import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'app-succeed',
  templateUrl: './succeed.component.html',
  styleUrls: ['./succeed.component.css']
})
export class SucceedComponent implements OnInit {
  private redirect: String = '';

  constructor(private activatedRoute: ActivatedRoute) {

  }

  ngOnInit() {
    this.redirect = this.activatedRoute.snapshot.queryParamMap.get('responseUrl')+
                  '?merchantId='+this.activatedRoute.snapshot.queryParamMap.get('merchantId')+
                  '&merchant_url='+this.activatedRoute.snapshot.queryParamMap.get('merchant_url')+
                  '&lapTransactionState='+this.activatedRoute.snapshot.queryParamMap.get('lapTransactionState')+
                  '&message='+this.activatedRoute.snapshot.queryParamMap.get('message')+
                  '&referenceCode='+this.activatedRoute.snapshot.queryParamMap.get('referenceCode')+
                  '&transactionId='+this.activatedRoute.snapshot.queryParamMap.get('transactionId')+
                  '&description='+this.activatedRoute.snapshot.queryParamMap.get('description')+
                  '&signature='+this.activatedRoute.snapshot.queryParamMap.get('signature')+
                  '&TX_VALUE='+this.activatedRoute.snapshot.queryParamMap.get('TX_VALUE')+
                  '&currency='+this.activatedRoute.snapshot.queryParamMap.get('currency')+
                  '&buyerEmail='+this.activatedRoute.snapshot.queryParamMap.get('buyerEmail');
    console.log(this.redirect);

  }

}
