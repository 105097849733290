import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router'

import swal from 'sweetalert2';
import { environment } from '../../environments/environment';
import { ResponseService } from '../model/responseService';
import { Payment } from '../model/payment';
import { InfoPayment } from '../model/infoPayment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private http: HttpClient, private router: Router) { }

  private httpHeaders = new HttpHeaders({ 'content-type': 'application/json', 'Authorization': 'Bearer ' + environment.api_express_token });

  getPaymentInfo(code: string, merchant_key: string): Observable<ResponseService> {
    return this.http.post<ResponseService>(
      environment.api_express + 'api/v/2.0/service/broker/invoice/info/',
      { 'code': code, 'merchant_key': merchant_key }, { headers: this.httpHeaders }).pipe(
        catchError(e => {
          console.error("Error: ", e.error.data['transaction-message']);
          swal('Error get information', e.error.detail, 'error');
          return throwError(e);
        })
      );
  }

  confirmPayment(payment: Payment, infoPayment: InfoPayment): Observable<ResponseService> {
    let splitName = payment.nameOnCard.split(' ');
    const firstName = splitName[0];
    splitName.shift();
    const lastName = splitName.join(' ');
    return this.http.post<ResponseService>(
      environment.api_express + 'api/v/2.0/service/broker/invoice/pay/',
      {
        'merchantKey': infoPayment.merchant_key,
        'creditCardNo': payment.creditCardNo,
        'creditCardCvvNo': payment.cvv,
        'creditCardExpiryMonth': payment.month,
        'creditCardExpiryYear': payment.year,
        'creditCardHolderFirstName': firstName,
        'creditCardHolderLastName': lastName,
        'creditCardHolderAddress1': payment.address1,
        'creditCardHolderAddress2': payment.address2,
        'creditCardHolderCity': payment.city,
        'creditCardHolderState': payment.state,
        'creditCardHolderZip': payment.zipcode,
        'amount': infoPayment.amount,
        'invoiceNumber': infoPayment.invoice_number,
        'tokenInvoice': infoPayment.code
      }, { headers: this.httpHeaders }).pipe(
        catchError(e => {
          this.router.navigate(['/payment/' + infoPayment.code + '/' + infoPayment.merchant_key]);
          if(e.error != undefined && e.error.data != undefined && e.error.data['transaction-message'] != undefined ){
            swal('Error confirm payment: ', e.error.data['transaction-message'], 'error');
          } else {
            swal('Error confirm payment', 'Error in server please check data in card', 'error');
          }
          return throwError(e);
        })
      );
  }

  sendCodeVerification(infoPayment: InfoPayment): Observable<ResponseService> {
    return this.http.post<ResponseService>(
      environment.api_express + 'api/v/1.0/service/sns/invoice/sendCode',
      {
        'merchant_key': infoPayment.merchant_key,
        'invoice_number': infoPayment.code,
        'phone': infoPayment.phone
      }, { headers: this.httpHeaders }).pipe(
        catchError(e => {
          console.error("Error: ", e.error.data['transaction-message']);
          swal('Error send information', e.error.detail, 'error');
          return throwError(e);
        })
      );
  }

  validateCodeVerification(payment: Payment, infoPayment: InfoPayment): Observable<ResponseService> {
    return this.http.post<ResponseService>(
      environment.api_express + 'api/v/1.0/service/sns/invoice/validate',
      {
        'merchant_key': infoPayment.merchant_key,
        'invoice_number': infoPayment.code,
        'phone': infoPayment.phone,
        'code': payment.codeVerification
      }, { headers: this.httpHeaders }).pipe(
        catchError(e => {
          console.error("Error: ", e.error.data['transaction-message']);
          swal('Error validation code', e.error.detail, 'error');
          return throwError(e);
        })
      );
  }

}
