import { Component, OnInit } from '@angular/core';
import { Payment } from '../model/payment';
import { Router, ActivatedRoute } from '@angular/router'
import { CATALOGS } from './catalogs.json';
import { PaymentService } from './payment.service'
import { InfoPayment } from '../model/infoPayment';
import { ResponseService } from '../model/responseService';
import swal from 'sweetalert2';

import * as jQuery from 'jquery';
import { PaymentCard } from '../model/paymentCard';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.css']
})
export class PaymentsComponent implements OnInit {
  private infoPayment: InfoPayment = new InfoPayment();
  private responseService: ResponseService;
  private payment: Payment = new Payment();
  private years: [] = CATALOGS[0].years;
  private months: [] = CATALOGS[1].months;
  private showSendCode: boolean = false;
  private codeVerification: number = 2;
  private showConfirmationCode: boolean = false;
  private phoneComponet: any;

  constructor(private router: Router, private activatedRoute: ActivatedRoute,
    private paymentService: PaymentService) { }


  ngOnInit() {
    this.loadDataPayment();
  }

  loadDataPayment() {
    this.activatedRoute.params.subscribe(params => {
      const code = params['code'];
      const merchant_key = params['merchant_key'];
      this.paymentService.getPaymentInfo(code, merchant_key).subscribe(
        responseService => {
          if (responseService.status == 'success') {
            this.infoPayment.merchant_key = merchant_key;
            this.infoPayment.code = code;
            this.infoPayment.type = responseService.data['type'];
            this.infoPayment.first_name = responseService.data['first_name'];
            this.infoPayment.last_name = responseService.data['last_name'];
            this.infoPayment.email = responseService.data['email'];
            this.infoPayment.phone = responseService.data['phone'];
            this.infoPayment.amount = responseService.data['amount'];
            this.infoPayment.invoice_number = responseService.data['invoice_number'];
            this.infoPayment.comments = responseService.data['comments'];
            this.infoPayment.dcreated = responseService.data['dcreated'];
            this.infoPayment.status = responseService.data['status'];
            if (responseService.data['payment'] != undefined &&
              responseService.data['payment']['amount'] == this.infoPayment.amount) {
              this.router.navigate(['/success']);
            }
          } else {
            console.log("Error en el servicio: ", responseService['msg']);
          }
        }
      );
    });
  }

  submitHandler() {
    const self = this;
    var forms = document.getElementsByClassName('needs-validation');
    Array.prototype.filter.call(forms, function (form) {
      form.addEventListener('submit', function (event) {
        if (!self.loadDataTarget() || form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        } else {
          if (self.payment.creditCardNoValid) {
            if (self.infoPayment.type == self.codeVerification) {
              self.showSendCode = true;
              setTimeout(() => {
                var input = document.querySelector("#phone");
                self.phoneComponet = window['intlTelInput'](input, {
                  // any initialisation options go here
                });
                self.phoneComponet.setNumber(self.infoPayment.phone);
              }, 500);
            } else {
              self.confirmPayment();
            }
          }
        }
        form.classList.add('was-validated');
      }, false);
    });
  }

  confirmPayment() {
    this.paymentService.confirmPayment(this.payment, this.infoPayment).subscribe(
      responseService => {
        if (responseService.status == 'success') {
          this.router.navigate(['/success'], { queryParams: responseService['data']['odoo'] });
        } else {
          this.showSendCode = false;
          this.showConfirmationCode = false;
          swal('Error confirm pay', responseService['data']['transaction-message'], 'error');
          console.log("Error en el servicio: ", responseService['data']['transaction-message']);
        }
      }
    );
  }

  sendCode() {
    this.infoPayment.phone = this.phoneComponet.getNumber();
    this.paymentService.sendCodeVerification(this.infoPayment).subscribe(
      responseService => {
        if (responseService.status == 'success') {
          this.showConfirmationCode = true;
        } else {
          swal('Error send code', responseService['data']['transaction-message'], 'error');
          console.log("Error en el servicio: ", responseService['data']['transaction-message']);
        }
      }
    );
  }

  confirmCode() {
    this.paymentService.validateCodeVerification(this.payment, this.infoPayment).subscribe(
      responseService => {
        if (responseService.status == 'success') {
          this.confirmPayment();
        } else {
          swal('Error confirm code', responseService['data']['transaction-message'], 'error');
          console.log("Error en el servicio: ", responseService['data']['transaction-message']);
        }
      }
    );
  }

  verificationData() {
    this.showSendCode = false;
    this.showConfirmationCode = false;
  }

  loadDataTarget() {
    this.payment.nameOnCard = jQuery("#cc-holder-name").val().toUpperCase();
    this.payment.creditCardNo = jQuery("#cc-number").val();
    this.payment.month = jQuery("#cc-expiration-month").val();
    this.payment.year = jQuery("#cc-expiration-year").val();
    this.payment.cvv = jQuery("#cc-ccv").val();
    jQuery("#cc-holder-name").focus();
    jQuery("#cc-number").focus();
    jQuery("#cc-expiration-month").focus();
    jQuery("#cc-expiration-year").focus();
    jQuery("#cc-ccv").focus();
    jQuery("#cc-number").focus();

    if (this.payment.nameOnCard === "" ||
      this.payment.creditCardNo === "" ||
      this.payment.month === "" ||
      this.payment.year === "" ||
      this.payment.cvv.toString().length < 3) {
        return false;
    } else {
      return true;
    }

  }

  processCard(paymentCard: PaymentCard) {
    console.log("pago: ", paymentCard);
    this.loadDataTarget();
  }
}
