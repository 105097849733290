import {Component, OnInit, ViewChild} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router'
import { InfoPayment } from '../model/infoPayment';
import { BtcService } from '../btc/btc.service'
import {CdTimerComponent} from "angular-cd-timer";

@Component({
  selector: 'app-btc',
  templateUrl: './btc.component.html',
  styleUrls: ['./btc.component.css']
})
export class BtcComponent implements OnInit {
  private infoPayment: InfoPayment = new InfoPayment();

  private address: string = "DATA";
  private pay_address: string = "DATA";
  private timer: number = 0;

  @ViewChild('basicTimer') basicTimer: CdTimerComponent;

  constructor(private activatedRoute: ActivatedRoute, private btcService: BtcService) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      const code = params['code'];
      const merchant_key = params['merchant_key'];
      this.btcService.getPaymentInfo(code, merchant_key).subscribe(
        responseService => {
          if (responseService.status == 'success') {
            this.infoPayment.invoice_number = responseService.data['invoice_number'];
            this.infoPayment.amount_btc = responseService.data['amount_btc'];
            this.infoPayment.btc_address = responseService.data['btc_address'];
            this.infoPayment.timer = responseService.data['timer'];
            this.address = this.infoPayment.btc_address;
            this.pay_address = responseService.data['pay_address'];;
            this.timer = this.infoPayment.timer

            this.infoPayment.code = code;
            this.infoPayment.merchant_key = merchant_key;
            this.infoPayment.amount = responseService.data['amount'];
            this.infoPayment.comments = responseService.data['comments'];
            this.infoPayment.currency = responseService.data['currency'];
            this.infoPayment.dcreated = responseService.data['dcreated'];
            this.infoPayment.email = responseService.data['email'];
            this.infoPayment.first_name = responseService.data['first_name'];
            this.infoPayment.last_name = responseService.data['last_name'];
            this.infoPayment.phone = responseService.data['phone'];
            this.infoPayment.status = responseService.data['status'];
            this.infoPayment.type = responseService.data['type'];
            this.basicTimer.startTime=this.timer;
            this.basicTimer.countdown=true;
            if (this.timer>0){
              this.basicTimer.start();
            }else{
              this.basicTimer.stop();
            }



          } else {
            console.log("Error en el servicio: ", responseService['msg']);
          }
        }
      );
    });
  }

}
