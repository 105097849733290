import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { QRCodeModule } from 'angularx-qrcode';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { PaymentsComponent } from './payments/payments.component'

import { RouterModule, Routes, Router } from '@angular/router'
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ConfirmCodeComponent } from './confirm-code/confirm-code.component';
import { SucceedComponent } from './succeed/succeed.component'

import { NgPaymentCardModule } from 'ng-payment-card';
import { BtcComponent } from './btc/btc.component';
import {CdTimerModule} from "angular-cd-timer/lib";


const routes: Routes = [
  { path: '', component: PaymentsComponent },
  { path: 'payment/:code/:merchant_key', component: PaymentsComponent },
  { path: 'confirmCode', component: ConfirmCodeComponent },
  { path: 'success', component: SucceedComponent },
  { path: 'btc/:code/:merchant_key', component: BtcComponent },
];

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    PaymentsComponent,
    ConfirmCodeComponent,
    SucceedComponent,
    BtcComponent,
  ],
    imports: [
        BrowserModule,
        HttpClientModule,
        FormsModule,
        RouterModule.forRoot(routes),
        NgPaymentCardModule,
        QRCodeModule,
        CdTimerModule,
    ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
