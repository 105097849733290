import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router'
import swal from 'sweetalert2';
import { environment } from '../../environments/environment';
import { ResponseService } from '../model/responseService';


@Injectable({
  providedIn: 'root'
})
export class BtcService {
  constructor(private http: HttpClient, private router: Router) { }

  private httpHeaders = new HttpHeaders({ 'content-type': 'application/json', 'Authorization': 'Bearer ' + environment.api_express_token });

  getPaymentInfo(code: string, merchant_key: string): Observable<ResponseService> {
    return this.http.post<ResponseService>(
      environment.api_express + 'api/v/2.0/service/broker/invoice/info/',
      { 'code': code, 'merchant_key': merchant_key }, { headers: this.httpHeaders }).pipe(
        catchError(e => {
          console.error("Error: ", e.error.data['transaction-message']);
          swal('Error get information', e.error.detail, 'error');
          return throwError(e);
        })
      );
  }
}
