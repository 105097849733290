export class Payment {
  nameOnCard: string;
  creditCardNo: string;
  month: string = '';
  year: string = '';
  cvv: number;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipcode: string;
  reference: string;
  codeVerification: string;
  creditCardNoValid: boolean = true;

  validateCreditCardNo() {
    this.creditCardNoValid = false;
    //Visa, Mastercard, Discover
    const regex = /^(?:4\d([\- ])?\d{6}\1\d{5}|(?:4\d{3}|5[1-5]\d{2}|6011)([\- ])?\d{4}\2\d{4}\2\d{4})$/;
    const validate = regex.exec(this.creditCardNo);
    if (validate != null) {
      this.creditCardNoValid = true;
    }
  }

}