// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  message:'development',
  api_heisenberg:'https://heisenberg.services.snbxt.com/',
  api_heisenberg_token: 'i2EMFjkm7T9d2pr1z8fNRikamxcRuZ',
  api_express: 'https://express.services.snbxt.com/',
  api_express_token: 'wvH2Q7hEebs4GLpdsCLOuoWl6ht2cF',
  urlSucceed:'https://ewallet.international/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
