export class InfoPayment {
  merchant_key:string;
  code:string;
  type: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  amount: number;
  invoice_number: string;
  comments: string;
  dcreated: string;
  status: number;
  amount_btc: number;
  btc_address:string;
  currency:number;
  timer:number;

}
